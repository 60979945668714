.carousel {
	height: 100%;
	position: relative;
	width: 100%;
}

.navigation-button {
	align-items: center;
	background: transparent;
	border: none;
	display: flex;
	height: 100px;
	justify-content: center;
	position: absolute;
	top: calc(50% - 50px);
	width: 70px;
	z-index: 1;

	&.left {
		left: 0px;
	}

	&.right {
		right: 0px;
	}

	.inner {
		align-items: center;
		background-color: white;
		border-radius: 5px;
		border: 1px solid #ececec;
		color: #8c8c8e;
		display: flex;
		font-size: 32px;
		height: 40px;
		justify-content: center;
		width: 36px;
	}
}

@media screen and (max-width: 769px) {
	.navigation-button {
		&.left {
			left: 110px;
		}

		&.right {
			right: 110px;
		}
	}
}

:global {
	.carousel__dot {
		background-color: white;
		border-radius: 50%;
		border: 1px solid #c7c7c8;
		height: 8px;
		margin: 0 1px;
		padding: 0;
		transition: background-color 0.3s ease;
		width: 8px;
	}

	.carousel__dot--selected {
		background-color: #c7c7c8;
	}
}